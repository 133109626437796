body {
    margin: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
   
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
   
  
  .s-box {
    min-height: 433px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 30px;
    background-color: #f7f7f9;
    box-shadow: 0 1px 1px rgba(0,0,0,0.15),
    0 2px 2px rgba(0,0,0,0.15),
    0 4px 4px rgba(0,0,0,0.15),
    0 8px 8px rgba(0,0,0,0.15);
  }
  label {
    margin-bottom: 3px;
  }
  .spinner-border {
    width: 1.3rem;
    height: 1.3rem;
    border-width: .1em;
  }
   
  .success-msg {
    color: #0f5132;
    text-align: center;
    margin-top: 120px;
  }
  .success-msg svg {
    font-size: 60px;
    border: 1px solid #0f5132;
    border-radius: 30px;
    padding: 10px;
  }
  .success-msg .title {
    font-size: 25px;
    margin-top: 10px;
  }