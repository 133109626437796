.footer {
    background-color: #003E7E;
    color: #fff;
    text-align: center;
    padding: 1rem;
  }
  
  .social-links {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .social-links a {
    color: #fff;
    text-decoration: none;
  }
  