.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #003E7E;
  }
  
  .navbar-logo {
    color: #FFFFFF;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    gap: 1.5rem;
  }
  
  .navbar-menu li a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .navbar-menu li a:hover {
    text-decoration: underline;
  }
  