.hero {
    position: relative;
    background: url("https://via.placeholder.com/1600x900") no-repeat center center/cover;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    padding: 2rem;
  }
  
  .hero-content {
    max-width: 700px;
    z-index: 2;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .hero-button {
    background-color: #FF6600;
    color: white;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .hero-button:hover {
    background-color: #ff5200;
  }
  