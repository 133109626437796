.highlights {
    padding: 2rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .highlights h2 {
    margin-bottom: 1.5rem;
  }
  
  .highlight-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1.5rem;
  }
  
  .highlight-card {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 1.5rem;
    border-radius: 5px;
    transition: box-shadow 0.3s;
  }
  
  .highlight-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .highlight-card h3 {
    margin-bottom: 0.5rem;
  }
  