body {
    margin: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
   
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
   
  
  .s-box {
    min-height: 433px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 30px;
    background-color: #f7f7f9;
    box-shadow: 0 1px 1px rgba(0,0,0,0.15),
    0 2px 2px rgba(0,0,0,0.15),
    0 4px 4px rgba(0,0,0,0.15),
    0 8px 8px rgba(0,0,0,0.15);
  }
  label {
    margin-bottom: 3px;
  }
  .spinner-border {
    width: 1.3rem;
    height: 1.3rem;
    border-width: .1em;
  }
   
  .success-msg {
    color: #0f5132;
    text-align: center;
    margin-top: 120px;
  }
  .success-msg svg {
    font-size: 60px;
    border: 1px solid #0f5132;
    border-radius: 30px;
    padding: 10px;
  }
  .success-msg .title {
    font-size: 25px;
    margin-top: 10px;
  }
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #003E7E;
  }
  
  .navbar-logo {
    color: #FFFFFF;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  .navbar-menu li a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .navbar-menu li a:hover {
    text-decoration: underline;
  }
  
.hero {
    position: relative;
    background: url("https://via.placeholder.com/1600x900") no-repeat center center/cover;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    padding: 2rem;
  }
  
  .hero-content {
    max-width: 700px;
    z-index: 2;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .hero-button {
    background-color: #FF6600;
    color: white;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .hero-button:hover {
    background-color: #ff5200;
  }
  
.highlights {
    padding: 2rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .highlights h2 {
    margin-bottom: 1.5rem;
  }
  
  .highlight-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  .highlight-card {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 1.5rem;
    border-radius: 5px;
    transition: box-shadow 0.3s;
  }
  
  .highlight-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .highlight-card h3 {
    margin-bottom: 0.5rem;
  }
  
.event-details {
    padding: 2rem;
    text-align: center;
  }
  
  .details-grid {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    max-width: 500px;
    margin: 1.5rem auto;
  }
  
  .register-button {
    background-color: #FF6600;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
  }
  
  .register-button:hover {
    background-color: #ff5200;
  }
  
.footer {
    background-color: #003E7E;
    color: #fff;
    text-align: center;
    padding: 1rem;
  }
  
  .social-links {
    list-style: none;
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .social-links a {
    color: #fff;
    text-decoration: none;
  }
  
@media (min-width: 1800px) {
    .container{
        max-width: 1500px;
    }
}

.smallWidth {
    background-color: #f8f9fa !important;
    width: 400px
  }

.smallCalendar {
    width: 200px;
    height: 400px;
}

.MuiContainer-maxWidthXs {
    width: 800px
}

.dx-scheduler {  
    min-height: 100px;  
}

.StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
  .product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: linear-gradient(
      135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%
    );
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .product-info {
    padding: 0 16px;
  }
  
  h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
  }
  
  h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
    font-weight: 500;
  }

  pre {
    background-color: #200d0d;
    border: 1px solid #050505;
    padding: 5px;
  }

codeBlock {
    color: #ebdfdf;
}
  
