.event-details {
    padding: 2rem;
    text-align: center;
  }
  
  .details-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 500px;
    margin: 1.5rem auto;
  }
  
  .register-button {
    background-color: #FF6600;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
  }
  
  .register-button:hover {
    background-color: #ff5200;
  }
  